<template>
  <div class="home-wrapper d-flex py-5">
    <div class="container position-relative">
      <h2 class="clientes-title text-center mb-5">Nossos clientes</h2>
      <div
        class="clientes-container d-flex justify-content-between row my-5 pt-3"
      >
        <div
          v-for="cliente in clientes"
          :key="cliente.nome"
          class="cliente d-flex justify-content-center flex-column col-6 col-md-3 align-items-center"
          @click="handleSelect(cliente.nome)"
        >
          <div class="cliente-logo mb-4" :class="{ active: cliente.active }">
            <img :src="cliente.logo" class="cliente-imagem" />
          </div>
          <div class="cliente-nome">
            <p class="text-center">{{ cliente.nome }}</p>
          </div>
        </div>
      </div>
      <div class="depoimento position-relative mb-5">
        <h2 class="depoimento-title">{{ activeClient.nome }}</h2>
        <p class="pb-4">
          {{ activeClient.depoimento }}
        </p>
        <div class="depoimento-autor">
          <p>{{ activeClient.autor }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      clientes: [
        {
          nome: "CEISC",
          logo: require("../assets/marcas_logos/ceisc.png"),
          active: true,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Conexo Seguros",
          logo: require("../assets/marcas_logos/conexo.jpg"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Horst",
          logo: require("../assets/marcas_logos/horst.jpg"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Jachetti Construtora",
          logo: require("../assets/marcas_logos/jachetti.jpg"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Mega Tchê",
          logo: require("../assets/marcas_logos/mega.jpg"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Floral Thérapi",
          logo: require("../assets/marcas_logos/therapi.png"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
        {
          nome: "Void Arquitetura",
          logo: require("../assets/marcas_logos/void.jpg"),
          active: false,
          autor: "",
          depoimento:
            "",
        },
      ],
      activeClient: {
          nome: "CEISC",
          logo: require("../assets/marcas_logos/ceisc.png"),
          active: true,
          autor: "",
          depoimento:
            "",
      },
    };
  },

  methods: {
    handleSelect(nome) {
      this.clientes.forEach((cliente) => {
        if (cliente.nome == nome) {
          cliente.active = true;
          this.activeClient = cliente;
        } else {
          cliente.active = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$dark-blue: #0f1c23;
$dark-blue-60: rgba(18, 24, 27, 0.6);
$dark-blue-50: rgba(15, 28, 35, 0.5);
$dark-white: #dce4e3;
$dark-gold: rgb(189, 175, 49);
$dark-gold-25: rgba(189, 175, 49, 0.25);
$dark-gold-07: rgba(189, 175, 49, 0.7);
$dark-gold-00: rgba(189, 175, 49, 0);
$error-red: rgb(201, 35, 35);

.home-wrapper {
  position: relative;
  width: 100%;
  background-color: $dark-blue;
  color: $dark-white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/backgrounds/bg_clientes.png");
    opacity: 0.03;
  }
}

.clientes-title {
  font-size: 3.5rem;
}

.cliente {
  .cliente-logo {
    display: flex;
    width: 150px;
    height: 150px;
    background-color: transparent;
    border-radius: 75px;
    border: 3px solid $dark-gold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out all;

    &.active {
      border: 1rem solid $dark-gold;

      .cliente-imagem {
        background-color: $dark-blue;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .cliente-logo {
      &:not(.active) {
        background-color: $dark-gold-25;
      }

      transform: scale(1.1);
    }
  }

  .cliente-imagem {
    width: 100px;
    border-radius: 50px;
  }
}
.depoimento {
  width: 100%;
  h2 {
    font-size: 2.5rem;
    color: $dark-gold;
  }
  span {
    color: $dark-gold;
    font-weight: 800;
  }
  a {
    color: $dark-gold;
    font-weight: 800;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -2rem;
    width: 4px;
    height: 100%;
    background-color: $dark-gold;
  }

  .depoimento-autor {
    color: $dark-gold;
    font-style: italic;
    text-align: end;
  }
}
</style>
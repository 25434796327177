<template>
  <header>
    <div
      id="header-desktop"
      :class="{ scrolled: scrolled }"
      class="d-none d-lg-flex"
    >
      <img src="../assets/logos/simbolo_branco.png" id="header-logo" />
      <nav class="nav-list d-flex">
        <div class="nav-link-wrapper">
          <a href="#home-start" class="nav-link">INÍCIO</a>
        </div>
        <div class="nav-link-wrapper">
          <a href="#registro-scroll" class="nav-link nav-orcamento">SOLICITE UM ORÇAMENTO</a>
        </div>
        <div class="nav-link-wrapper">
          <a href="sobre.html" class="nav-link">QUEM SOMOS</a>
        </div>
        <div class="nav-link-wrapper">
          <a href="registro.html" class="nav-link">REGISTRO DE MARCAS</a>
        </div>
        <div class="nav-link-wrapper">
          <a href="blog" class="nav-link">BLOG</a>
        </div>
      </nav>
    </div>
    <div
      id="header-mobile"
      :class="{ enlarged: enlarged }"
      class="d-flex d-lg-none p-3 flex-column"
    >
      <div>
        <svg-icon
          class="svg-icon hamburger-icon"
          type="mdi"
          :path="pathMenu"
          @click="this.enlarged = !this.enlarged"
        ></svg-icon>
      </div>
      <nav class="d-flex flex-column">
        <a
          @click="this.enlarged = false"
          href="#home-start"
          class="header-mobile-links"
          >INÍCIO</a
        >
        <a
          @click="this.enlarged = false"
          href="#registro"
          class="header-mobile-links"
          >SOLICITE UM ORÇAMENTO</a
        >
        <a @click="this.enlarged = false" href="sobre.html" class="header-mobile-links"
          >QUEM SOMOS</a
        >
        <a @click="this.enlarged = false" href="registro.html" class="header-mobile-links"
          >REGISTRO DE MARCAS</a
        >
        <a @click="this.enlarged = false" href="blog" class="header-mobile-links"
          >BLOG</a
        >
      </nav>
    </div>
  </header>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenu } from "@mdi/js";

export default {
  components: { SvgIcon },

  data() {
    return {
      scrolled: false,
      enlarged: false,
      pathMenu: mdiMenu,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$dark-blue: #0f1c23;
$dark-blue-60: rgba(15, 28, 35, 0.6);
$dark-blue-50: rgba(15, 28, 35, 0.5);
$dark-white: #dce4e3;
$dark-gold: #bdaf31;
$dark-gold-25: rgba(189, 175, 49, 0.25);
$dark-gold-07: rgba(189, 175, 49, 0.7);
$dark-gold-00: rgba(189, 175, 49, 0);
$error-red: #c92323;

#header-desktop {
  width: 100%;
  background-color: transparent;
  position: fixed;
  z-index: 50;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;

  &.scrolled {
    background-color: $dark-blue;
  }
}

#header-logo {
  width: 70px;
  background-color: $dark-gold;
}

.nav-link {
  color: $dark-white;
  transition: 0.2s ease-in-out all;
  padding: 0.5rem 1rem;
  border: 3px solid transparent;

  &:hover {
    background-color: $dark-gold-25;
  }

  &.nav-orcamento {
    border: 3px solid $dark-gold;
  }
}

.nav-link-wrapper:not(:last-child) {
  margin-right: 1rem;
}

#header-mobile {
  width: 100%;
  height: 62px;
  position: fixed;
  top: 0;
  z-index: 50;
  transition: 0.2s ease-in-out all;
  background-color: $dark-blue;
  overflow: hidden;
  color: $dark-white;

  &.enlarged {
    height: 100%;
  }
}
.hamburger-icon {
  width: 2rem;
  height: 2rem;
  color: $dark-gold;
  margin-bottom: 16px;

  &:hover {
    cursor: pointer;
  }
}
.header-mobile-links {
  text-align: center;
  color: $dark-gold;
  padding: 1.5rem 0;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out all;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    background-color: $dark-gold-25;
  }
}
</style>
<template>
  <div class="home-wrapper d-flex" id="home-start">
    <div class="content container d-flex position-relative">
      <div class="registre position-relative">
        <h1 class="registre-title">
          Registre sua marca <span>rapidamente</span>
        </h1>
        <p class="col-6 px-0 pb-4">
          Utilize nosso simulador de orçamentos, confira valores e entre em
          contato conosco imediatamente
        </p>
        <a class="scroll" @click="scroll"
          >Veja como
          <svg-icon class="chevron" type="mdi" :path="pathChevron"></svg-icon
        ></a>
        <div
          class="social mt-5 social-small position-absolute d-flex d-sm-none"
        >
          <a href="https://www.facebook.com/azevedovendraminmarcas" class="social-icon mx-2">
            <svg-icon
              class="svg-icon"
              type="mdi"
              :path="pathFacebook"
            ></svg-icon>
          </a>
          <a href="#" class="social-icon mx-2 detail">
            <svg-icon class="svg-icon" type="mdi" :path="pathWhats"></svg-icon>
          </a>
          <a href="#" class="social-icon mx-2">
            <svg-icon
              class="svg-icon"
              type="mdi"
              :path="pathInstagram"
            ></svg-icon>
          </a>
          <a href="#" class="social-icon mx-2">
            <svg-icon class="svg-icon" type="mdi" :path="pathEmail"></svg-icon>
          </a>
        </div>
      </div>

      <div class="social position-absolute d-none d-sm-flex">
        <a href="#" class="social-icon my-2">
          <svg-icon class="svg-icon" type="mdi" :path="pathFacebook"></svg-icon>
        </a>
        <a href="https://wa.me/5551992361390&text=Ol%C3%A1%2C%20vi%20este%20contato%20e%20gostaria%20de%20conversar%20sobre%20marca!" class="social-icon my-2 detail">
          <svg-icon class="svg-icon" type="mdi" :path="pathWhats"></svg-icon>
        </a>
        <a href="https://www.instagram.com/av_marcas/" class="social-icon my-2">
          <svg-icon
            class="svg-icon"
            type="mdi"
            :path="pathInstagram"
          ></svg-icon>
        </a>
        <a href="mailto:contato@azevedovendramin.com.br" class="social-icon my-2">
          <svg-icon class="svg-icon" type="mdi" :path="pathEmail"></svg-icon>
        </a>
      </div>
    </div>
    <div id="registro-scroll"></div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiChevronDown,
  mdiWhatsapp,
  mdiFacebook,
  mdiInstagram,
  mdiEmail,
} from "@mdi/js";

export default {
  components: {
    SvgIcon,
  },

  methods: {
    scroll() {
      document.getElementById("registro-scroll").scrollIntoView({
        behavior: "smooth",
      });
    },
  },

  data() {
    return {
      pathChevron: mdiChevronDown,
      pathWhats: mdiWhatsapp,
      pathFacebook: mdiFacebook,
      pathInstagram: mdiInstagram,
      pathEmail: mdiEmail,
    };
  },
};
</script>
<style lang="scss" scoped>
$dark-blue: #0f1c23;
$dark-white: #dce4e3;
$dark-gold: rgb(189, 175, 49);
$dark-gold-07: rgba(189, 175, 49, 0.7);
$dark-gold-00: rgba(189, 175, 49, 0);

.home-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $dark-blue;
  color: $dark-white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/backgrounds/bg_home.png");
    opacity: 0.06;
  }

  .content {
    align-items: center;

    .registre {
      width: 100%;
      h1 {
        font-size: 3.5rem;
      }
      span {
        color: $dark-gold;
        font-weight: 800;
      }
      a {
        color: $dark-gold;
        font-weight: 800;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -2rem;
        width: 4px;
        height: 110%;
        background-color: $dark-gold;
      }
    }
  }
}

.social {
  flex-direction: column;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .social-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 25px;
    color: $dark-white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $dark-gold-00;
    transition: 0.2s ease-in-out all;

    &:hover {
      border: 2px solid $dark-gold;
      transition: 0.3s ease-in-out all;
    }
    &.detail {
      background-color: $dark-gold-07;

      &:hover {
        background-color: $dark-gold;
      }
    }

    .svg-icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

.social-small {
  flex-direction: row;
  left: 0;
  top: 100%;
  transform: none;
}

.chevron {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.scroll {
  &:hover {
    cursor: pointer;
  }
}

#registro-scroll{
  position: absolute;
  bottom: 101px;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-6px);
  }
  60% {
    -webkit-transform: translateY(-3px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-6px);
  }
  60% {
    -moz-transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  60% {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
</style>
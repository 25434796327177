<template>
  <div class="home-wrapper d-flex py-5">
    <div class="container">
      <h2 class="text-center title mb-5">Registre sua <span>marca</span></h2>

      <div
        class="form d-flex flex-md-row flex-column justify-content-between mb-5"
      >
        <div class="d-flex flex-column input-wrapper mb-4 mb-md-0">
          <svg-icon class="svg-icon" type="mdi" :path="pathAccount"></svg-icon>
          <input
            placeholder="Nome"
            type="text"
            name="nome"
            v-model="form.nome"
          />
          <div v-if="error.nome" class="form-error pt-2">
            <p>Qual o seu nome?</p>
          </div>
        </div>
        <div class="d-flex flex-column input-wrapper">
          <svg-icon
            class="svg-icon"
            type="mdi"
            :path="pathBriefcase"
          ></svg-icon>
          <input
            placeholder="Empresa"
            type="text"
            name="empresa"
            v-model="form.empresa"
          />
          <div v-if="error.empresa" class="form-error pt-2">
            <p>Qual o nome de sua empresa?</p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="submit-btn py-2 px-4 mb-3" @click="submit">
          Entre em contato
        </button>
      </div>
      <h3 class="text-center">Respondemos em menos de 1 dia!</h3>
    </div>
    <div id="clientes-scroll"></div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAccount, mdiBriefcase } from "@mdi/js";
export default {
  components: {
    SvgIcon,
  },

  data() {
    return {
      form: {},
      error: { nome: false, empresa: false },
      pathAccount: mdiAccount,
      pathBriefcase: mdiBriefcase,
    };
  },

  methods: {
    submit: function (e) {
      if (this.form.nome) {
        this.error.nome = false;
      }
      if (this.form.empresa) {
        this.error.empresa = false;
      }
      if (this.form.nome && this.form.empresa) {
        window.open(
          "https://wa.me/5551992361390?text=Olá! Sou " +
            this.form.nome +
            " da empresa " +
            this.form.empresa +
            ", gostaria de realizar um orçamento para registro de marca."
        );
      } else {
        if (!this.form.nome) {
          this.error.nome = true;
        }
        if (!this.form.empresa) {
          this.error.empresa = true;
        }
      }
      e.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
$dark-blue: #0f1c23;
$dark-blue-60: rgba(15, 28, 35, 0.6);
$dark-blue-50: rgba(15, 28, 35, 0.5);
$dark-white: #dce4e3;
$dark-gold: rgb(189, 175, 49);
$dark-gold-25: rgba(189, 175, 49, 0.25);
$dark-gold-07: rgba(189, 175, 49, 0.7);
$dark-gold-00: rgba(189, 175, 49, 0);
$error-red: rgb(201, 35, 35);

.home-wrapper {
  position: relative;
  width: 100%;
  background-color: $dark-white;
  color: $dark-blue;
}

.title {
  font-size: 3.5rem;
  span {
    color: $dark-gold;
    font-weight: 800;
  }
}

.form {
  .input-wrapper {
    position: relative;
    width: 47%;
  }
  input {
    background: none;
    border: none;
    border-bottom: 1px solid $dark-blue-50;
    padding: 1rem 0 1rem 3rem;

    &::placeholder {
      color: $dark-blue-60;
      // font-family: 'Roboto';
    }
    &::-webkit-input-placeholder {
      color: $dark-blue-60;
      font-family: "Roboto";
      position: absolute;
      left: 50px;
    }
    &:-ms-input-placeholder {
      color: $dark-blue-60;
      font-family: "Roboto";
    }
  }
  .svg-icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    color: $dark-blue;
  }
}

.submit-btn {
  border: 3px solid $dark-gold;
  background: transparent;
  transition: 0.2s ease-in-out all;
  font-size: 1.5rem;

  &:hover {
    background-color: $dark-gold-25;
    transition: 0.2s ease-in-out all;
  }
}

.form-error {
  color: $error-red;
}

#clientes-scroll {
  position: absolute;
  bottom: 101px;
}

@media only screen and (max-width: 767px) {
  .input-wrapper {
    width: 100% !important;
  }
}
</style>
<template>
  <footer>
    <div class="footer-white">
      <div class="container py-4">
        <div class="row pl-5 pl-md-0">
          <div class="col-12 col-md-6 col-lg-4 mb-4 d-flex flex-column">
            <p class="footer-title">Encontre no site</p>
            <a href="/">Início</a>
            <a href="#registro-scroll">Orçamento</a>
            <a href="sobre.html">Sobre nós</a>
            <a href="registro.html">Sobre registro de marcas</a>
            <a href="#clientes-scroll">Nossos clientes</a>
            <a href="blog">Blog</a>
          </div>
          <div class="col-12 col-md-6 mb-4 col-lg-4">
            <p class="footer-title">Fale conosco</p>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathAccount"
              ></svg-icon>
              <p>Carlos Eduardo Vendramin</p>
              <p>Advogado OAB/RS nº 102.031</p>
            </div>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathWhatsapp"
              ></svg-icon>
              <a href="https://wa.me/5551992361390">(51) 99236 1390</a>
            </div>
            <div class="position-relative mb-5">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathEmail"
              ></svg-icon>
              <p>carlos@azevedovendramin.com.br</p>
            </div>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathAccount"
              ></svg-icon>
              <p>Douglas Matheus de Azevedo</p>
              <p>Advogado OAB/RS nº 112.140</p>
            </div>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathWhatsapp"
              ></svg-icon>
              <a href="https://wa.me/5551997758057">(51) 99775 8057</a>
            </div>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathEmail"
              ></svg-icon>
              <p>douglas@azevedovendramin.com.br</p>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4 col-lg-4">
            <p class="footer-title">Endereço</p>
            <div class="position-relative">
              <svg-icon
                class="svg-icon"
                type="mdi"
                :path="pathLocation"
              ></svg-icon>
              <a href="https://www.google.com.br/maps/place/Av.+Sete+de+Setembro,+277+-+Moinhos,+Lajeado+-+RS,+95900-000/@-29.4553366,-51.978293,17z/data=!3m1!4b1!4m5!3m4!1s0x951c617682702b31:0x7500b93491a7f3b6!8m2!3d-29.4553413!4d-51.9761043">Av. Sete de Setembro, n 277, sala 503, Lajeado/RS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="footer-dark py-3 d-flex align-items-center justify-content-center"
    >
      <p class="mb-0 text-center">
        2020 © Azevedo & Vendramin Advocacia - Todos os direitos reservados
      </p>
    </div>
  </footer>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMapMarker, mdiAccount, mdiEmail, mdiWhatsapp } from "@mdi/js";

export default {
  components: { SvgIcon },

  data() {
    return {
      pathLocation: mdiMapMarker,
      pathAccount: mdiAccount,
      pathEmail: mdiEmail,
      pathWhatsapp: mdiWhatsapp,
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
$dark-blue: #0f1c23;
$dark-blue-60: rgba(15, 28, 35, 0.6);
$dark-blue-50: rgba(15, 28, 35, 0.5);
$dark-white: #dce4e3;
$dark-gold: rgb(189, 175, 49);
$dark-gold-25: rgba(189, 175, 49, 0.25);
$dark-gold-07: rgba(189, 175, 49, 0.7);
$dark-gold-00: rgba(189, 175, 49, 0);
$error-red: rgb(201, 35, 35);

.footer-white {
  background-color: $dark-white;
  color: $dark-blue;

  .footer-title {
    font-weight: 600;
    margin-bottom: 1rem !important;
  }

  a {
    color: $dark-blue;
    margin-bottom: 0.4rem;
  }

  p {
    margin-bottom: 0.4rem;
  }

  .svg-icon {
    position: absolute;
    left: -2rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
.footer-dark {
  background-color: $dark-blue;
  color: $dark-white;
}
</style>
<template>
<div>
  <Header></Header>
  <Home></Home>
  <Registro id="registro"></Registro>
  <Nossosclientes></Nossosclientes>
  <Footer></Footer>
</div>
</template>

<script>
import Header from '../../components/Header';
import Home from '../../components/Home';
import Registro from '../../components/Registro';
import Footer from '../../components/Footer';
import Nossosclientes from '../../components/NossosClientes';
export default {
  name: 'App',
  components: {
    Home,
    Registro,
    Header,
    Footer,
    Nossosclientes
  }
}
</script>

<style>

</style>
